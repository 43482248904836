@import '../../assets/sass/variables';
@import 'src/assets/sass/themes';

.wrapper-content-login {
  font-family: 'Open Sans', sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: $hovered-blue;
  height: 100vh;
  position: relative;

  .content {
    padding: 0rem 4.6rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    background: $white;

    @media only screen and (max-width: 768px) {
      padding: 0rem 2.6rem;
    }

    @media screen and (min-width: 768px) and (max-width: 990px) {
      padding: 0rem;
    }

    .logo {
      width: 36.6rem;
      margin: 5rem 0 2rem 0;

      @media only screen and (max-width: 768px) {
        width: 20rem;
        margin: 3rem 0 1rem 0;
      }
    }

    .description {
      font-size: 2.3rem;

      @include themify($themes) {
        color: themed('main');
      }

      width: 72rem;
      text-align: center;
      margin: 1rem 0rem;

      @media only screen and (max-width: 768px) {
        width: 100%;
      }

      span {
        font-weight: 700;
      }
    }

    .formWrapper {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;

      .inputs {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .error {
          font-weight: 700;

          p {
            margin: 0;
          }

          .error-message {
            color: #ca312a !important;
            display: block;
            margin-top: 0.5rem;
            font-size: 1rem;
          }

          select,
          input {
            border: 1px solid $red !important;
          }
        }

        label {
          @include themify($themes) {
            color: themed('main');
          }

          font-weight: 700;
          font-size: 1.4rem;
        }

        input {
          width: 26.8rem;
          height: 4.2rem;
          font-size: 1.4rem;
          margin: 0.5rem 0rem;

          &::placeholder {
            font-size: 1.4rem;
          }
        }

        p {
          @include themify($themes) {
            color: themed('main');
          }

          opacity: 0.5;
          font-size: 1rem;
          text-transform: none;
          text-align: right;
          font-weight: normal;
        }
      }

      p {
        text-transform: uppercase;

        @include themify($themes) {
          color: themed('main');
        }

        font-size: 2rem;
        text-align: center;
      }

      i {
        padding: 0rem 1rem;
      }

      button {
        width: 26.8rem;
        height: 4.1rem;
        border-radius: 0.5rem;
        margin-bottom: 2.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 700;

        span {
          font-size: 1.2rem;
        }

        .checkIcon {
          padding: 0rem 0.4rem;
          font-size: 1.6rem;
        }
      }
    }

    .microsoft-btn {
      width: 26.8rem !important;
      margin-bottom: 2rem;
      background: #2f2f2f;
      height: 4.1rem;
      border-radius: 0.5rem;
      color: $white;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      font-size: 1.3rem;
      cursor: pointer;

      svg {
        width: 3rem;
        height: 3rem;
        margin-right: 0.5rem;
      }

    }

    .microsoft-btn:hover {
      background: #818181;
    }
  }

  .footer {
    background: $white;
    display: flex;
    width: 100%;
    height: 2.5rem;
    align-items: center;
    justify-content: space-between;

    @media only screen and (max-width: 768px) {
      display: none;
    }

    & img {
      padding: 0rem 1.6rem;
    }

    .footerContent {
      display: flex;
      justify-content: center;
      gap: 3.2rem;
      font-size: 1.1rem;

      @include themify($themes) {
        color: themed('main');
      }

      & :first-child {
        font-weight: 700;
      }
    }

    & .language-container {
      display: flex;
      align-items: center;
      gap: 1rem;

      @include themify($themes) {
        color: themed('main');
      }

      margin-right: 1rem;
    }
  }
}