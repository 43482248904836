@import '../../../assets/sass/variables';
@import 'src/assets/sass/themes';

.item-container-shop-battery {
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
  justify-content: center;
  margin: 1rem 0;
  position: relative;
  min-height: 40rem;
  width: 100%;
  align-items: center;
  margin-top: 2rem;

  .item-shop {
    width: 30rem;
    height: 32.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $blue-10;

    .header {
      display: flex;
      flex-direction: row;
      width: 90%;
      margin-top: 1.8rem;

      .checkboxCellTab {
        position: absolute;
        right: 0rem;
        z-index: 30;
      }

      .img {
        width: 6.5rem;
        text-align: center;

        img {
          max-width: 6.5rem;
          max-height: 6.5rem;
        }
      }

      .content {
        display: flex;
        flex-direction: column;
        margin-left: 2rem;

        .title {
          text-align: left;
          margin-bottom: 0.5rem;
          font-size: 1.4rem;
          font-weight: bold;

          @include themify($themes) {
            color: themed('main');
          }
        }

        .reference {
          text-align: left;
          margin-bottom: 0.5rem;
          font-size: 1.2rem;
          font-weight: 400;
          line-height: 1.5;

          @include themify($themes) {
            color: themed('main');
          }
        }
      }
    }

    .body {
      margin-top: 0.8rem;
      display: flex;
      flex-direction: column;
      width: 90%;
      background-color: $blue-10;

      .description-short {
        text-align: left;
        margin-bottom: 0.5rem;
        font-size: 1.1rem;
        font-weight: bold;

        @include themify($themes) {
          color: themed('main');
        }
      }

      .description {
        text-align: left;
        margin-bottom: 0.5rem;
        font-size: 1.1rem;


        @include themify($themes) {
          color: themed('main');
        }

        line-height: 1.3;

        span {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 6;
          overflow: hidden;
          position: relative;
          mask-image: linear-gradient(180deg, rgba(0, 0, 0, 1) 860%, rgba(0, 0, 0, 0));
          -webkit-mask-image: linear-gradient(180deg, rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0));

        }
      }

      .top-offer-checkbox-container {
        >* {
          cursor: pointer;
        }

        label {
          margin: 0.3rem 0;
          display: inline-block;
          float: left;
          text-align: left;
          font-size: 1.1rem;

          @include themify($themes) {
            color: themed('main');
          }

          line-height: 1.3;
        }

        margin-bottom: 0.5rem;
      }
    }

    .footer {
      display: flex;
      flex-direction: row;
      border-top: 0.1rem solid $gray;
      align-items: center;
      margin-top: auto;
      width: 27.8rem;
      height: 8.85rem;
      padding: 1rem 0;

      .quantity {
        width: 17rem;
        flex-direction: column;
        margin: 0rem 1rem;

        .quantity-disponibility {
          align-items: none;
        }

        .disponibility-porduct {
          margin-top: 0.5rem;

          @include themify($themes) {
            color: themed('main');
          }

          .disponibility-count {
            @include themify($themes) {
              color: themed('main');
            }

            font-weight: bold;
          }
        }
      }

      .cart {
        text-align: center;
        margin-bottom: 0.2rem;
        font-size: 1.8rem;
        font-weight: 600;

        .cart-price {
          font-size: 1.8rem;

          @include themify($themes) {
            color: themed('main');
          }

          font-weight: bold;
        }

        .cart-button {
          display: flex;
          gap: 0.3rem;

          .button-edit {
            padding: 0.3rem;
            width: 7rem;
            height: 3.5rem;
            border: none;

            @include themify($themes) {
              background-color: themed('main');
            }

            border-radius: 0.8rem;
            cursor: pointer;

            &:hover {
              background-color: $blue-4;
            }

            .icon-edit {
              color: $white;
              font-size: 1.7rem;
            }
          }

          .button-delete {
            padding: 0.3rem;
            width: 7rem;
            height: 3.5rem;
            border: none;
            background-color: $red;
            border-radius: 0.8rem;
            cursor: pointer;

            &:hover {
              background-color: $red-2;
            }

            .icon-delete {
              color: $white;
              font-size: 1.7rem;
            }
          }
        }
      }
    }
  }

  .no-data {
    font-size: 2.4rem;
    font-weight: bold;

    @include themify($themes) {
      color: themed('main');
    }

    opacity: 60%;
    margin-top: 5rem;
    white-space: nowrap;
  }
}