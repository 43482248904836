@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.retailer-login {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background: transparent url('../../../assets/images/login-background.png') no-repeat fixed;
  background-size: cover;

  &__card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: 83rem;
    height: 53rem;
    background-color: white;
    border-radius: 0.5rem;
    position: relative;

    @media only screen and (max-width: 990px) and (min-width: 768px) {
      width: 80%;
    }

    &-header {
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-top: 5rem;
      gap: 2.3rem;

      &-description {
        font-size: 2.3rem;
        font-weight: 400;
        color: $black;
        margin-bottom: 2rem;
        text-align: center;
      }
    }

    &-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 23rem;
      height: 100%;

      &-title {
        font-size: 1.4rem;
        font-weight: 200;
        color: $light-blue;
        margin-bottom: 2rem;
        text-align: center;
      }

      &-input_container {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 100%;

        &-input_item {
          &.error label {
            color: $red;
          }

          &.error .error-message {
            margin-top: 0.5rem;
            font-size: 1rem;
          }

          &.error input {
            border: 1px solid $red;
            color: $red;
          }

          display: flex;
          flex-direction: column;
          width: 100%;

          & label {
            font-size: 1.2rem;
            font-weight: bold;
            color: $black;
            margin-bottom: 0.5rem;
          }

          &__forgot_password {
            align-self: flex-end;
            font-size: 1.2rem;
            font-weight: 400;
            color: rgba(39, 80, 159, 0.5);
            margin-bottom: 0.5rem;
          }
        }
      }
    }
  }

  .microsoft-btn {
    width: 100%;
    margin: 1rem 0rem 2rem 0rem;
    background: #2f2f2f;
    height: 4.1rem;
    border-radius: 0.5rem;
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 1.3rem;
    cursor: pointer;

    svg {
      width: 3rem;
      height: 3rem;
      margin-right: 0.5rem;
    }

  }

  .microsoft-btn:hover {
    background: #818181;
  }

  .error {
    color: $red;
  }

  .check {
    padding-right: 0.625px;
  }

  &__forgot_password {
    display: flex;
    justify-content: flex-end;

    @include themify($themes) {
      color: themed('main');
    }

    cursor: pointer;

    & a {
      font-size: 1.2rem;
      font-weight: 400;
      color: rgba(39, 80, 159, 0.5);
    }
  }
}