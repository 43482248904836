@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.simple-ad-wrapper {
  padding: 2.5rem 0;
  max-width: 100%;
  display: flex;
  flex-flow: column nowrap;
  gap: 2rem;


  .swiper-slide {
    max-height: 100% !important;
    max-width: 100% !important;

    .ad-image {
      max-width: 100%;
      max-height: 100%;
      display: flex;
    }

    img {
      object-fit: contain;
      display: block;
      max-width: 100%;
      max-height: 100%;
    }

    .ad-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .promotion-container-slider {
        display: flex;
        flex-direction: column;
        background-color: transparent;
        padding: 0rem 2rem 0rem 2rem;

        .title {
          font-size: 2rem;
          font-weight: 700;
          color: #171818;
          text-align: center;
          margin-top: 1rem;
        }

        .description {
          font-size: 1.5rem;
          font-weight: 400;
          color: #171818;
          text-align: center;
          margin-top: 1rem;
        }
      }
    }
  }

}

.app-layout-mobile .swiper-wrapper.with-pagination,
.app-layout-tablet .swiper-wrapper.with-pagination {
  //padding-bottom: 0;
}