@import 'assets/sass/variables';
@import 'src/assets/sass/themes';

.part-view-price {
  padding-left: 0.625rem;
  text-align: center;
  font-size: 1.875rem;
  white-space: nowrap;
  font-weight: bold;

  .spinner {
    font-size: 1.2rem;
  }

  span {
    display: inline-block;
    font-size: 1.2rem;
  }
}

.part-view-price {
  .oem-price {
    display: flex;
    align-items: center;
    flex-direction: column;
    line-height: 1rem;

    .divider {
      border-bottom: 1px solid $blue-4;
      width: 1rem;
      margin: 0.7rem 0;
      display: flex;
      align-items: center;
    }

    .vat-price {
      font-size: 1.7rem;

      @include themify($themes) {
        color: themed('main');
      }

      & .vat-label {
        font-size: 0.8rem;
      }
    }
  }

  .price-currency {
    font-size: 1.875rem;
  }
}

.car-part-price {
  display: flex;
  align-items: center;
  padding-left: 0.625rem;
  text-align: center;
  font-size: 1.6rem;
  white-space: nowrap;
  font-weight: bold;
  gap: 0.4rem;
  margin: 0.4rem 0;

  .oem-price {
    display: flex;
    align-items: center;
    flex-direction: column;
    line-height: 1rem;

    .divider {
      border-bottom: 1px solid $blue-4;
      width: 1rem;
      margin: 0.5rem 0;
      display: flex;
      align-items: center;
    }

    .vat-price {
      font-size: 1.3rem;

      @include themify($themes) {
        color: themed('main');
      }

      & .vat-label {
        font-size: 0.7rem;
      }
    }
  }

  .spinner {
    font-size: 1.2rem;
  }

  .currency {
    font-size: 1.4rem;
  }

  span {
    display: inline-block;
    font-size: 1rem;

    p {
      margin: 0;
    }
  }

  .price-currency {
    font-size: 1.6rem;
  }
}