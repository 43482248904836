@import "src/assets/sass/variables";
@import "src/assets/sass/themes";

.item-view-modal {
    padding: 1rem 2rem;
    box-sizing: border-box;

    width: 75rem;

    @media screen and (max-width: 768px) {
        width: 100%;
    }

    .placeholderRed {
        color: $red;
    }

    &__header {
        display: flex;
        justify-content: flex-end;
        padding-right: 1rem;

        &-close {
            font-size: 1.8rem;
            cursor: pointer;

            &:hover {
                opacity: 60%;
            }
        }
    }

    &__content {
        display: flex;
        gap: 3rem;
        min-height: 30rem;

        @media screen and (max-width: 768px) {
            min-height: 55rem;

        }

        @media screen and (max-width: 990px) {
            flex-direction: column;
            align-items: center;
            gap: 3rem;
        }

        &-left {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            &-image {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 30rem;
                height: 20rem;
                border: 1px solid $gray;
                position: relative;

                &-product {
                    max-width: 20rem;
                    max-height: 20rem;
                }

                &-supplier {
                    position: absolute;
                    bottom: 0.5rem;
                    right: 0.5rem;
                    max-width: 5rem;
                    max-height: 5rem;
                }
            }

            .barcode {
                align-self: flex-end;
                max-width: 9rem;
                padding-top: 2rem;
                padding-bottom: 1rem;

                img {
                    max-width: 100%;
                    height: 5rem;
                }
            }

            & .button {
                & .item-technical_sheet {
                    font-weight: bold;
                    width: 100%;
                    height: 3.4rem;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;

                    @include themify($themes) {
                        background-color: themed("main");
                    }

                    border-radius: 0.8rem;
                    border: none;
                    cursor: pointer;
                    color: $white;
                    gap: 1rem;
                    font-size: 1.2rem;
                    line-height: 1.7rem;
                    text-align: center;

                    svg {
                        font-size: 1.6rem;

                        @include themify($themes) {
                            fill: themed("main");
                        }
                    }

                    &:hover {
                        background-color: $white;

                        @include themify($themes) {
                            color: themed("main");
                            border: 0.1rem solid themed("main");
                        }

                        svg {
                            font-size: 1.2rem;

                            circle {
                                @include themify($themes) {
                                    stroke: themed("main") !important;
                                }
                            }
                        }
                    }

                    .spinner {
                        width: 3rem;
                        flex-grow: initial;

                        svg {
                            font-size: 1.2rem;

                            circle {
                                stroke: $white;
                            }
                        }
                    }
                }
            }


        }

        &-right {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 1rem;

            @media screen and (max-width: 990px) {
                gap: 2rem;
            }

            &-tags {
                font-size: 1.5rem;
                display: flex;
                flex-direction: column;
                min-height: 20rem;
                max-height: 30rem;
                overflow-y: auto;

                &-tag {
                    display: inline;
                    gap: 0.5rem;

                    & .value {
                        font-weight: bold;

                        & svg {
                            cursor: pointer;

                            &:hover {
                                opacity: 60%;
                            }
                        }
                    }
                }
            }

            &-description {
                margin-top: 1rem;

                &-value {
                    @include themify($themes) {
                        color: themed("main");
                        border: 0.1rem solid themed("main");
                    }

                    max-width: 33rem;
                    font-size: 1.5rem;
                    padding: 0.5rem;
                    min-height: 5rem;
                    max-height: 15rem;
                    overflow-y: auto;
                }
            }

            & .bottom {
                display: flex;
                flex-direction: column;
                gap: 1rem;
                width: 35rem;

                @media screen and (max-width: 768px) {
                    width: 100%;
                }

                & .availability {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    gap: 1rem;
                    width: 100%;

                    @media screen and (max-width: 990px) {
                        margin: 0 auto;
                    }

                    @media screen and (max-width: 990px) {
                        justify-content: center;
                    }
                }

                & .item-cart_btn {
                    font-weight: bold;
                    width: 100%;
                    height: 3.4rem;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    background-color: #ee2939;
                    border-radius: 0.8rem;
                    border: none;
                    cursor: pointer;
                    color: $white;
                    gap: 1rem;
                    font-size: 1.2rem;
                    line-height: 1.7rem;
                    text-align: center;

                    svg {
                        font-size: 1.6rem;
                        fill: $blue-icon-color;
                    }

                    &:hover {
                        background-color: $white;
                        color: #ee2939;
                        border: 0.1rem solid #ee2939;

                        svg {
                            font-size: 1.2rem;

                            circle {
                                stroke: #ee2939 !important;
                            }
                        }
                    }

                    .spinner {
                        width: 3rem;
                        flex-grow: initial;

                        svg {
                            font-size: 1.2rem;

                            circle {
                                stroke: $white;
                            }
                        }
                    }
                }
            }
        }
    }

    .brand-slider-modal {
        background-color: #f6f6f6;
        max-width: 75rem;
        width: 100%;
        height: 0rem;
        overflow: hidden;

        @media screen and (max-width: 768px) {
            max-width: 100%;
        }

        .swiper-container .navigation {
            flex: 0;
            padding: 5px;
        }


        .swiper-slide-modal {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            max-width: 23rem;

            @media screen and (max-width: 768px) {
                max-width: 100%;
            }

            flex-direction: column;
            gap: 1rem;

            .image {
                max-height: 7rem;
                max-width: 14rem;
            }

            a {
                color: $black;
                font-size: 1.4rem;
                font-weight: 600;
                text-decoration: none;
            }
        }


    }

    .brand-slider-modal-show {

        height: 8rem;
        transition: height 1s ease;
        overflow: auto;
        margin: 0.5rem 0rem;
        padding: 0.5rem 0rem;

        @media screen and (max-width: 768px) {
            height: 12rem;
        }
    }
}