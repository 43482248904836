@import 'src/assets/sass/variables';
@import 'src/assets/sass/themes';

.item-container-top-offert {
    width: 60rem;
    height: 19.5rem;
    border-radius: 0.5rem;

    @include themify($themes) {
        background: themed('secondary');
    }

    @media screen and (max-width: 1220px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 30rem;
        height: auto;
    }

    padding-left: 2rem;

    .reference {
        text-align: left;
        margin-bottom: 0.5rem;
        font-size: 1.6rem;
        font-weight: 700;
        line-height: 1.5;
        padding: 1rem;

        @media screen and (max-width: 1220px) {

            text-align: center;


        }

        @include themify($themes) {
            color: themed('main');
        }
    }

    .item-shop {
        display: flex;
        flex-direction: row;
        align-items: center;

        height: 10rem;

        @media screen and (max-width: 1220px) {
            display: flex;
            flex-direction: column;
            justify-content: center;

            height: auto;
        }


        .item-column-1 {
            display: flex;
            flex-direction: column;

            margin: 1rem;
            gap: 1rem;
            width: 12rem;

            @media screen and (max-width: 1220px) {

                justify-content: center;


            }


            .img {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 10rem;
                height: 10rem;
                border: 0.1rem solid $gray;

                img {
                    max-width: 10rem;
                    max-height: 10rem;
                    margin: auto;
                }
            }

            .product-name {
                font-weight: 700;
                line-height: 1.5;
                font-size: 1.6;

                @media screen and (max-width: 1220px) {

                    text-align: center;


                }

                @include themify($themes) {
                    color: themed('main');
                }
            }
        }

        .item-column-2 {
            display: flex;
            flex-direction: column;
            width: 33rem;
            margin: 0rem;
            height: 100%;

            @media screen and (max-width: 1220px) {

                justify-content: center;
                width: 100%;
                align-items: center;
                text-align: center;

            }


            .description {
                display: flex;
                flex-direction: column;
                height: 100%;



                label {
                    text-align: left;
                    margin-bottom: 0.5rem;
                    font-size: 1.4rem;
                    font-weight: bold;

                    @media screen and (max-width: 1220px) {


                        text-align: center;

                    }

                    @include themify($themes) {
                        color: themed('main');
                    }
                }

                p {
                    color: #171818;
                    font-size: 1.4rem;
                }


                span {
                    color: #171818;
                    font-size: 1.4rem;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 3;
                    overflow: hidden;
                    position: relative;
                    mask-image: linear-gradient(180deg, rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0));
                    -webkit-mask-image: linear-gradient(180deg, rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0));
                }



            }
        }

        .item-column-3 {
            display: flex;
            flex-direction: column;
            width: 15rem;
            margin: 1rem;
            gap: 1rem;
            justify-content: center;
            text-align: center;
            align-items: center;


            @keyframes flash {

                0%,
                100% {
                    opacity: 1;
                }

                50% {
                    opacity: 0;
                }
            }

            .explosion {
                position: relative;
                width: 150px;
                height: 120px;

                background-image: url('/assets/images/image-price.jpg');
                background-repeat: no-repeat;

                display: flex;
                align-items: center;
                justify-content: center;
                animation: flash 1.2s infinite;
            }

            .explosion span {
                width: max-content;
                text-align: center;
                font-size: 1.5rem;
                font-weight: bold;
                color: red;
            }

            .btn {
                width: 15rem;
            }
        }


    }

    .no-data {
        font-size: 2.4rem;
        font-weight: bold;

        @include themify($themes) {
            color: themed('main');
        }

        opacity: 60%;
        margin-top: 5rem;
        white-space: nowrap;
    }
}